<template>
  <div>
    <b-navbar type="dark" variant="dark">
      <b-navbar-brand small>
        <span v-if="online">
          <a href="/"><BIconHouse />&nbsp;soilhealth.app</a>
        </span>
        <span v-else><BIconHouse />&nbsp;soilhealth.app</span>
        <br />
        <span v-if="authObserver" class="fullName">
          {{ authObserver.my_name }}
        </span>
        <div v-if="projectName">
          <p class="tight">
            <span class="projectName">{{ projectName }}</span>
            <br />
            <span v-if="!status" class="statusText"> (public data only)</span>
          </p>
        </div>
      </b-navbar-brand>

      <b-button
        v-if="outbox && online"
        class="ml-8"
        variant="warning"
        @click="$router.push({ name: 'outbox' })"
        >Go to Outbox to upload observations
      </b-button>

      <b-navbar-nav class="ml-auto">
        <template>
          <b-nav-item-dropdown text="Menu" right>
            <b-dropdown-item v-if="!authObserver && online">
              <a href="/accounts/login/" @click="login()">Log in</a>
            </b-dropdown-item>
            <b-dropdown-item v-if="online">
              <router-link :to="{ path: '/projects' }">Projects</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="online">
              <router-link :to="{ path: '/help' }">Help</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="online">
              <router-link :to="{ path: '/about' }">About</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="projectName">
              <router-link :to="{ path: '/' + projectName }"
                >{{ projectName }} home</router-link
              >
            </b-dropdown-item>
            <b-dropdown-item v-if="projectName && online">
              <router-link :to="{ path: '/' + projectName + '/observations' }"
                >Observation map</router-link
              >
            </b-dropdown-item>
            <b-dropdown-item v-if="status">
              <router-link :to="{ path: '/' + projectName + '/questions' }"
                >Questions/data forms</router-link
              >
            </b-dropdown-item>
            <b-dropdown-item v-if="projectName && online">
              <router-link :to="{ path: '/' + projectName + '/posts' }"
                >Posts</router-link
              >
            </b-dropdown-item>
            <b-dropdown-item v-if="projectName && online">
              <router-link :to="{ path: '/' + projectName + '/maps' }"
                >Farm/ranch maps</router-link
              >
            </b-dropdown-item>
            <b-dropdown-item v-if="status">
              <router-link :to="{ name: 'locate' }">Add new site</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="status && online">
              <router-link :to="{ name: 'posts-new' }"
                >Add new post</router-link
              >
            </b-dropdown-item>
            <b-dropdown-item v-if="authObserver && online">
              <a href="/accounts/logout/" @click="logout()">Log out</a>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </template>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import api from '@/api.js'
import { contextMixin } from '@/mixins/context.js'
import localForageMixin from '@/mixins/localForageMixin.js'
import onlineMixin from '@/mixins/onlineMixin.js'

export default {
  name: 'NavBar',
  mixins: [contextMixin, localForageMixin, onlineMixin],
  methods: {
    getAuthObserver() {
      const expiration_ms = 14 * 24 * 60 * 60 * 1000
      api
        .get('/auth-observer/')
        .then((response) => {
          // if online
          this.authObserver = response.data
          this.online = true
          // add a new timestamp
          this.authObserver.lastAccess = new Date().getTime()
          // add the default project
          const myProject =
            this.authObserver.my_projects[
              this.authObserver.my_projects.length - 1
            ].project_name
          this.authObserver.myProject = myProject
          localStorage.setItem(
            'authObserver',
            JSON.stringify(this.authObserver)
          )
        })
        .catch(() => {
          // then load authObserver from localStorage
          this.authObserver =
            JSON.parse(localStorage.getItem('authObserver')) || {}
          // this.online = false // leave out, perhaps causing error
          // if this fails or timestamp is expired
          if (
            this.authObserver.lastAccess &&
            this.authObserver.lastAccess < new Date().getTime() - expiration_ms
          ) {
            localStorage.clear()
            this.authObserver = {}
            alert(
              'SORRY: your login for soilhealth.app is over 14 days old. Please log in while online with this device to renew your use.'
            )
          }
        })
        .finally(() => {
          // route to the Observer's default project
          if (this.authObserver?.myProject && this.$route.path === '/') {
            this.$router.push({ path: this.authObserver?.myProject })
          }
        })
    }
  },
  created() {
    this.getAuthObserver()
    // this.initOutbox()
  }
}
</script>
<style scoped>
.projectName {
  color: white;
  font-weight: 600;
  font-size: 1.5rem;
}

a {
  color: inherit;
}
p.tight {
  line-height: 1;
}
.statusText {
  color: rgb(255, 130, 130);
  font-size: 0.8rem;
}
.fullName {
  color: rgb(195, 253, 171);
  font-size: 0.8rem;
}
</style>
